<template>
    <div class="panel panel-default ">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.startDate.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.endDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Regional Sales Rep</label>
                <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.salesUserID.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Sales Support Rep</label>
                <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.insideSalesUserID.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
              <label>National Sales Team</label>
              <select class="form-control" v-model="state.optionFields.nationalSalesTeams.val">
                <option value="0"></option>
                <option v-for="[id, location] in nationalSalesTeams" v-bind:key="id" :value="id">{{ location }}</option>
              </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' v-model="state.optionFields.cdiToMeasure.val" :true-value="1" :false-value=null> CDI to Measure</label>
            </div>
            <div v-if="showReminders" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' v-model="state.optionFields.sendReminders.val" :true-value="1" :false-value=null> Send Reminders</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import appStore from '@/store/App.store.js';
    import DatePicker from "@/components/utils/DatePicker";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    export default {
        name: "Options",
        components: { DatePicker, Typeahead },
        data() {
            return {
                state: store.state,
                appState: appStore.state,
                app: appStore,
                toggleHide: true,
                nationalSalesTeams: [
                  [0,'Options Not Available']],
              }
        },
      created() {
        this.get_national_sales_teams();
      },
      methods: {
        get_national_sales_teams: function() {
          store.customAPI('menu_builder/national_sales_teams').then(res => this.nationalSalesTeams = res);
        }
      },
        computed: {
            showReminders: function() {
                return this.$appStore.hasRole(this.appState.user.roles);
            }
        }
    }
</script>

<style scoped>

</style>