<template>
    <div>
        <h1>Unverified Orders</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from './unverified_quotes_rpt_opt.vue';
    import Results from './utils/Results';
    import Fields from "./utils/Fields";

    export default {
        name: "unverified_quotes_rpt",
        components: {
            Fields,
            Options,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: '', qsField: '[datetimes][quote_date_from]'},
                    endDate: {val: new Date().toLocaleDateString(), qsField: '[datetimes][quote_date_to]'},
                    salesUserID: {val: 0, qsField: '[numbers][d.sales_user_id]'},
                    insideSalesUserID: {val: 0, qsField: '[numbers][d.inside_sales_user_id]'},
                    nationalSalesTeams: {val: 0, qsField: '[numbers][d.national_sales_team_id]'},
                    cdiToMeasure: {val: null, qsField: '[isset][cdi_to_measure]'},
                    sendReminders: {val: null, qsField: '[send_reminders]'},

                },
                groupByFields: {},
                dataFields: {
                    quote_id: {label: 'ID', checked: true},
                    quote_date: {label: 'Date', checked: true},
                    distributor: {label: 'Customer', checked: true},
                    company_name: {label: 'Company', checked: true},
                    job_tag: {label: 'Job Tag', checked: true},
                    cdi_to_measure: {label: 'CDI to Measure', checked: true},
                    brand: {label: 'Brand', checked: false},
                    outside_sales: {label: 'Regional Sales Rep', checked: false},
                    inside_sales: {label: 'Sales Support Rep', checked: false},
                    national_sales_team: {label: 'National Sales Team', checked: false},
                    state: {label: 'State', checked: false},
                    territory: {label: 'Territory', checked: false},
                    curb_txt: {label: 'Item Numbers', checked: false},
                    distributor_po: {label: 'PO', checked: false},
                    drawings_verified: {label: 'Drawings Verified', checked: false},
                    distributor_po_date: {label: 'PO Date', checked: false},
                    intended_order_date: {label: 'Order Date', checked: false}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Unverified Orders']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>